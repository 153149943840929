import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { OrganisationsFacadeService, UsersFacadeService } from '@exe/client/client-web/core/store';

export const LogoutGuard: CanDeactivateFn<boolean> = () => {
  const usersFacadeService = inject(UsersFacadeService);
  const organisationsFacadeService = inject(OrganisationsFacadeService);
  usersFacadeService.clearLoggedInUser();
  organisationsFacadeService.clearOrganisation();
  return true;
};
