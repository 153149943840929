import { Database } from './supabase.types';

export enum Tables {
  ACTIONS = 'actions',
  ACTION_EVENTS = 'actionEvents',
  PROJECTS = 'projects',
  CUSTOMERS = 'customers',
  ORDERS = 'orders',
  PRODUCTS = 'products',
  USERS = 'users',
  ORGANISATIONS = 'organisations'
}

// Generic types
export type Row<T extends keyof Database['public']['Tables']> = Database['public']['Tables'][T]['Row'];
export type InsertDto<T extends keyof Database['public']['Tables']> = Database['public']['Tables'][T]['Insert'];
export type UpdateDto<T extends keyof Database['public']['Tables']> = Database['public']['Tables'][T]['Update'];
export type DeleteDto<T extends keyof Database['public']['Tables']> = Database['public']['Tables'][T]['Row'][];

export interface Action extends Row<Tables.ACTIONS> {}
export interface ActionInsertDto extends InsertDto<Tables.ACTIONS> {}
export interface ActionUpdateDto extends UpdateDto<Tables.ACTIONS> {}
export interface ActionDeleteDto extends DeleteDto<Tables.ACTIONS> {}

export interface ActionEvent extends Row<Tables.ACTION_EVENTS> {
  product?: Product,
  action?: Action,
  user?: User
}
export interface ActionEventInsertDto extends InsertDto<Tables.ACTION_EVENTS> {}
export interface ActionEventUpdateDto extends UpdateDto<Tables.ACTION_EVENTS> {}
export interface ActionEventDeleteDto extends DeleteDto<Tables.ACTION_EVENTS> {}

export interface Project extends Row<Tables.PROJECTS> {}
export interface ProjectInsertDto extends InsertDto<Tables.PROJECTS> {}
export interface ProjectUpdateDto extends UpdateDto<Tables.PROJECTS> {}
export interface ProjectDeleteDto extends DeleteDto<Tables.PROJECTS> {}

export interface Customer extends Row<Tables.CUSTOMERS> {}
export interface CustomerInsertDto extends InsertDto<Tables.CUSTOMERS> {}
export interface CustomerUpdateDto extends UpdateDto<Tables.CUSTOMERS> {}
export interface CustomerDeleteDto extends DeleteDto<Tables.CUSTOMERS> {}

export interface Order extends Row<Tables.ORDERS> {
  project?: Project;
  customer?: Customer;
}
export interface OrderInsertDto extends InsertDto<Tables.ORDERS> {}
export interface OrderUpdateDto extends UpdateDto<Tables.ORDERS> {}
export interface OrderDeleteDto extends DeleteDto<Tables.ORDERS> {}

export interface Product extends Row<Tables.PRODUCTS> {
  lastActionEvent?: ActionEvent;
}
export interface ProductInsertDto extends InsertDto<Tables.PRODUCTS> {}
export interface ProductUpdateDto extends UpdateDto<Tables.PRODUCTS> {}
export interface ProductDeleteDto extends DeleteDto<Tables.PRODUCTS> {}

export interface Organisation extends Row<Tables.ORGANISATIONS> {}
export interface OrganisationInsertDto extends InsertDto<Tables.ORGANISATIONS> {}
export interface OrganisationUpdateDto extends UpdateDto<Tables.ORGANISATIONS> {}
export interface OrganisationDeleteDto extends DeleteDto<Tables.ORGANISATIONS> {}

export interface User extends Row<Tables.USERS> {
  organisation?: Organisation
}
export interface UserInsertDto extends InsertDto<Tables.USERS> {
  password: string;
}
export interface UserUpdateDto extends UpdateDto<Tables.USERS> {}
export interface UserDeleteDto extends DeleteDto<Tables.USERS> {}

export interface Request {
  table?: string,
  select?: string,
  filters?: FilterBy[],
  order?: OrderBy,
  skip?: number,
  take?: number
}

export interface FilterBy {
  column: string;
  operator: string;
  value: any;
}

export interface OrderBy {
  column: string,
  options: {
    foreignTable?: string,
    ascending: boolean
  }
}

export enum Operators {
  EQ = 'eq',
  NEQ = 'neq',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  LIKE = 'like',
  ILIKE = 'ilike',
  IS = 'is'
}

export enum Buckets {
  DOCUMENTATIONS = 'documentations'
}

export enum UserRoles {
  MANAGER = 'Manager',
  MEMBER = 'Member'
}

export interface UploadedFile {
  name: string,
  fullPath: string,
  thumbnailPath?: string
}
