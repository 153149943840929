import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { statisticsReducer } from './statistics.reducers';
import { StatisticsEffects } from './statistics.effects';

@NgModule({
  imports: [StoreModule.forFeature('statistics', statisticsReducer), EffectsModule.forFeature([StatisticsEffects])]
})
export class StatisticsModule {}
