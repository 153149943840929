import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as fromOrders from './orders.actions';
import {
  selectActiveOrders,
  selectActiveOrdersIsLoaded,
  selectOrders, selectOrdersErrors,
  selectOrdersIsLoaded,
  selectOrdersIsLoading, selectOrdersTotalCount
} from './orders.selectors';
import { ApiResponsePaginated } from '../store.model';
import {
  Order,
  OrderDeleteDto,
  OrderInsertDto, OrderUpdateDto,
  Request,
  Tables
} from '@exe/client/client-web/core/supabase';
import { Actions, ofType } from '@ngrx/effects';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class OrdersFacadeService {
  #store = inject(Store);
  #actions$ = inject(Actions);

  isActiveOrdersLoaded = toSignal(this.#store.select(selectActiveOrdersIsLoaded), { initialValue: false });
  activeOrders = toSignal(this.#store.select(selectActiveOrders), { initialValue: []  });

  clearOrders(): void {
    return this.#store.dispatch(fromOrders.clearOrders());
  }

  fetchOrders(getOrdersRequestDto: Request): void {
    getOrdersRequestDto = {
      ...getOrdersRequestDto,
      select: `*, project:${Tables.PROJECTS}!inner(*), customer:${Tables.CUSTOMERS}!inner(*)`
    }
    return this.#store.dispatch(fromOrders.fetchOrders({ payload: getOrdersRequestDto }));
  }

  isOrdersLoading$(): Observable<boolean> {
    return this.#store.select(selectOrdersIsLoading).pipe(
      filter(ordersIsLoading => !!ordersIsLoading)
    );
  }

  isOrdersLoaded$(): Observable<boolean> {
    return this.#store.select(selectOrdersIsLoaded).pipe(
      filter(ordersIsLoaded => !!ordersIsLoaded)
    );
  }

  getOrders$(): Observable<ApiResponsePaginated<Order>> {
    return this.#store.select(selectOrders).pipe(
      filter(orders => !!orders)
    );
  }

  getOrdersErrors$(): Observable<HttpErrorResponse[]> {
    return this.#store.select(selectOrdersErrors).pipe(
      filter(errors => !!errors)
    );
  }

  getOrdersTotalCount$(): Observable<number> {
    return this.#store.select(selectOrdersTotalCount).pipe(
      filter(ordersTotalCount => !!ordersTotalCount)
    );
  }

  addOrder(orderCreateDto: OrderInsertDto): Observable<void> {
    this.#store.dispatch(fromOrders.addOrder({ payload: orderCreateDto }));

    return this.#actions$.pipe(
      ofType(fromOrders.addOrderSuccess),
      first()
    ) as Observable<void>;
  }

  updateOrder(orderUpdateDto: OrderUpdateDto): Observable<void> {
    this.#store.dispatch(fromOrders.updateOrder({ payload: orderUpdateDto }));

    return this.#actions$.pipe(
      ofType(fromOrders.updateOrderSuccess),
      first()
    ) as Observable<void>;
  }

  deleteOrders(orderDeleteDto: OrderDeleteDto): Observable<void> {
    this.#store.dispatch(fromOrders.deleteOrders({ payload: orderDeleteDto }));

    return this.#actions$.pipe(
      ofType(fromOrders.deleteOrdersSuccess),
      first()
    ) as Observable<void>;
  }

  fetchActiveOrders(): void {
    return this.#store.dispatch(fromOrders.fetchActiveOrders());
  }
}
