import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Functions, SupabaseService } from '@exe/client/client-web/core/supabase';
import * as fromStatistics from './statistics.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

@Injectable()
export class StatisticsEffects {
  #actions$ = inject(Actions);
  #supabaseService = inject(SupabaseService);

  fetchActionsPerUser = createEffect(() =>
    this.#actions$.pipe(
      ofType(fromStatistics.fetchActionsPerUser),
      switchMap(() => {
        return from(this.#supabaseService.client.rpc(Functions.STATISTICS_GET_USER_ACTIONS))
      }),
      map(({data}) => fromStatistics.fetchActionsPerUserSuccess({
        payload: data
      })),
      catchError((error) => of(fromStatistics.fetchActionsPerUserFailure({ payload: error })))
    )
  );

  fetchProductsPerUser = createEffect(() =>
    this.#actions$.pipe(
      ofType(fromStatistics.fetchProductsPerUser),
      switchMap(() => {
        return from(this.#supabaseService.client.rpc(Functions.STATISTICS_GET_PRODUCTS_PER_USER))
      }),
      map(({data}) => fromStatistics.fetchProductsPerUserSuccess({
        payload: data
      })),
      catchError((error) => of(fromStatistics.fetchProductsPerUserFailure({ payload: error })))
    )
  );

  fetchOrdersPerMonth = createEffect(() =>
    this.#actions$.pipe(
      ofType(fromStatistics.fetchOrdersPerMonth),
      switchMap(() => {
        return from(this.#supabaseService.client.rpc(Functions.STATISTICS_GET_ORDERS_PER_MONTH))
      }),
      map(({data}) => fromStatistics.fetchOrdersPerMonthSuccess({
        payload: data
      })),
      catchError((error) => of(fromStatistics.fetchOrdersPerMonthFailure({ payload: error })))
    )
  );

  fetchOrdersPerCustomer = createEffect(() =>
    this.#actions$.pipe(
      ofType(fromStatistics.fetchOrdersPerCustomer),
      switchMap(() => {
        return from(this.#supabaseService.client.rpc(Functions.STATISTICS_GET_ORDERS_PER_CUSTOMER))
      }),
      map(({data}) => fromStatistics.fetchOrdersPerCustomerSuccess({
        payload: data
      })),
      catchError((error) => of(fromStatistics.fetchOrdersPerCustomerFailure({ payload: error })))
    )
  );

  fetchOrderSummary = createEffect(() =>
    this.#actions$.pipe(
      ofType(fromStatistics.fetchOrderSummary),
      switchMap((payload) => {
        return from(this.#supabaseService.client.rpc(Functions.STATISTICS_GET_ORDER_SUMMARY, {
          order_id: payload.orderId
        }))
      }),
      map(({data}) => fromStatistics.fetchOrderSummarySuccess({
        payload: data
      })),
      catchError((error) => of(fromStatistics.fetchOrderSummaryFailure({ payload: error })))
    )
  );
}
