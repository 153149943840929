import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService, Tables } from '@exe/client/client-web/core/supabase';
import * as fromOrganisations from './index';

@Injectable()
export class OrganisationsEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getOrganisation = createEffect(() =>
    this.actions$.pipe(
      ofType(fromOrganisations.fetchOrganisation),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from(Tables.ORGANISATIONS).select().eq('id', payload));
      }),
      map(({data }) => fromOrganisations.fetchOrganisationSuccess({
        payload: data![0]
      })),
      catchError((error) => of(fromOrganisations.fetchOrganisationFailure({ payload: error })))
    )
  );
}
