/* istanbul ignore file */

import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { URL_TREE } from '@exe/client/client-web/shared/navigation';
import { LoginGuard, LogoutGuard } from '@exe/client/client-web/shared/guards';

const FEATURE_MODULES = {
  AUTH: () => import('@exe-trace/client/client-web/feature/feature-auth').then(m => m.FeatureAuthModule),
  DASHBOARD: () => import('@exe/client/client-web/feature/feature-dashboard').then(m => m.FeatureDashboardModule),
  NOT_AUTHORIZED: () => import('@exe/client/client-web/shared/not-authorized').then(m => m.NotAuthorizedModule),
  NOT_FOUND: () => import('@exe/client/client-web/shared/not-found').then(m => m.NotFoundModule),
};

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: URL_TREE.DASHBOARD.URL,
    pathMatch: 'full',
  },
  {
    path: URL_TREE.AUTH.URL,
    loadChildren: FEATURE_MODULES.AUTH,
  },
  {
    path: URL_TREE.DASHBOARD.URL,
    loadChildren: FEATURE_MODULES.DASHBOARD,
    canMatch: [LoginGuard],
    canDeactivate: [LogoutGuard]
  },
  {
    path: URL_TREE.NOT_AUTHORIZED.URL,
    loadChildren: FEATURE_MODULES.NOT_AUTHORIZED,
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: FEATURE_MODULES.NOT_FOUND,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
