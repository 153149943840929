import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponseState, initialApiResponseState } from '../store.model';
import {
  ActionsPerUserStatistics,
  OrdersPerCustomerStatistics,
  OrdersPerMonthStatistics, OrderSummaryStatistics,
  ProductsPerUserStatistics
} from './statistics.model';
import * as fromStatistics from './index';

export interface StatisticsState {
  users: {
    actionsPerUser: ApiResponseState<ActionsPerUserStatistics>
    productsPerUser: ApiResponseState<ProductsPerUserStatistics>;
  },
  orders: {
    ordersPerMonth: ApiResponseState<OrdersPerMonthStatistics>,
    ordersPerCustomer: ApiResponseState<OrdersPerCustomerStatistics>,
    orderSummary: ApiResponseState<OrderSummaryStatistics>,
  }
}

export const initialStatisticsState: StatisticsState = {
  users: {
    actionsPerUser: initialApiResponseState,
    productsPerUser: initialApiResponseState
  },
  orders: {
    ordersPerMonth: initialApiResponseState,
    ordersPerCustomer: initialApiResponseState,
    orderSummary: initialApiResponseState
  }
};

const reducer = createReducer(
  initialStatisticsState,
  on(fromStatistics.clearActionsPerUser, (state) => {
    return {
      ...state,
      users: {
        ...state.users,
        actionsPerUser: initialStatisticsState.users.actionsPerUser
      }
    };
  }),
  on(fromStatistics.fetchActionsPerUser, (state) => {
    return {
      ...state,
      users: {
        ...state.users,
        actionsPerUser: {
          ...state.users.actionsPerUser,
          isLoading: true,
          isLoaded: false
        }
      }
    };
  }),
  on(fromStatistics.fetchActionsPerUserSuccess, (state, { payload }) => {
    return {
      ...state,
      users: {
        ...state.users,
        actionsPerUser: {
          ...state.users.actionsPerUser,
          isLoading: false,
          isLoaded: true,
          data: payload
        }
      }
    };
  }),
  on(fromStatistics.fetchProductsPerUser, (state) => {
    return {
      ...state,
      users: {
        ...state.users,
        productsPerUser: {
          ...state.users.productsPerUser,
          isLoading: true,
          isLoaded: false,
        }
      }
    };
  }),
  on(fromStatistics.fetchProductsPerUserSuccess, (state, { payload }) => {
    return {
      ...state,
      users: {
        ...state.users,
        productsPerUser: {
          ...state.users.productsPerUser,
          isLoading: false,
          isLoaded: true,
          data: payload
        }
      }
    };
  }),
  on(fromStatistics.fetchOrdersPerMonth, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        ordersPerMonth: {
          ...state.orders.ordersPerMonth,
          isLoading: true,
          isLoaded: false,
        }
      }
    };
  }),
  on(fromStatistics.fetchOrdersPerMonthSuccess, (state, { payload }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        ordersPerMonth: {
          ...state.orders.ordersPerMonth,
          isLoading: false,
          isLoaded: true,
          data: payload
        }
      }
    };
  }),
  on(fromStatistics.fetchOrdersPerCustomer, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        ordersPerCustomer: {
          ...state.orders.ordersPerCustomer,
          isLoading: true,
          isLoaded: false,
        }
      }
    };
  }),
  on(fromStatistics.fetchOrdersPerCustomerSuccess, (state, { payload }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        ordersPerCustomer: {
          ...state.orders.ordersPerCustomer,
          isLoading: false,
          isLoaded: true,
          data: payload
        }
      }
    };
  }),
  on(fromStatistics.fetchOrderSummary, (state) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        orderSummary: {
          ...state.orders.orderSummary,
          isLoading: true,
          isLoaded: false,
        }
      }
    };
  }),
  on(fromStatistics.fetchOrderSummarySuccess, (state, { payload }) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        orderSummary: {
          ...state.orders.orderSummary,
          isLoading: false,
          isLoaded: true,
          data: payload
        }
      }
    };
  }),
);

export function statisticsReducer(state = initialStatisticsState, actions: Action) {
  return reducer(state, actions);
}
