import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponsePaginated, ApiResponseState, initialApiResponseState } from '../store.model';
import { ActionEvent } from '@exe/client/client-web/core/supabase';
import * as fromActionEvents from './index';

export interface ActionEventsState {
  actionEvents: ApiResponseState<ApiResponsePaginated<ActionEvent>>;
  lastActionEvents: ApiResponseState<ActionEvent[]>;
}

export const initialActionEventsState: ActionEventsState = {
  actionEvents: initialApiResponseState,
  lastActionEvents: initialApiResponseState
};

const reducer = createReducer(
  initialActionEventsState,
  on(fromActionEvents.clearActionEvents, (state) => {
    return {
      ...state,
      actionEvents: initialActionEventsState.actionEvents
    };
  }),
  on(fromActionEvents.fetchActionEvents, (state) => {
    return {
      ...state,
      actionEvents: {
        ...state.actionEvents,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromActionEvents.fetchActionEventsSuccess, (state, { payload }) => {
    return {
      ...state,
      actionEvents: {
        ...state.actionEvents,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromActionEvents.fetchActionEventsFailure, (state, { payload }) => {
    return {
      ...state,
      actionEvents: {
        ...state.actionEvents,
        isLoading: false,
        isLoaded: false,
        errors: [...state.actionEvents.errors, payload]
      }
    };
  }),
  on(fromActionEvents.clearLastActionEvents, (state) => {
    return {
      ...state,
      lastActionEvents: initialActionEventsState.lastActionEvents
    };
  }),
  on(fromActionEvents.fetchLastActionEvents, (state) => {
    return {
      ...state,
      lastActionEvents: {
        ...state.lastActionEvents,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromActionEvents.fetchLastActionEventsSuccess, (state, { payload }) => {
    return {
      ...state,
      lastActionEvents: {
        ...state.lastActionEvents,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromActionEvents.fetchLastActionEventsFailure, (state, { payload }) => {
    return {
      ...state,
      lastActionEvents: {
        ...state.lastActionEvents,
        isLoading: false,
        isLoaded: false,
        errors: [...state.actionEvents.errors, payload]
      }
    };
  }),
);

export function actionEventsReducer(state = initialActionEventsState, actions: Action) {
  return reducer(state, actions);
}
