import { inject } from '@angular/core';
import { CanMatchFn, Route } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { UsersFacadeService } from '@exe/client/client-web/core/store';

export const RoleGuard: CanMatchFn = (route: Route): Observable<boolean> => {
  const usersFacadeService = inject(UsersFacadeService);
  const requiredRoles = route.data?.['requiredRoles'] as string[] || [];

  return usersFacadeService.isLoggedInUserLoaded$().pipe(
    switchMap(() => usersFacadeService.getLoggedInUser$()),
    map(loggedInUser => requiredRoles.includes(loggedInUser?.role as string))
  );
};
