import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, Observable } from 'rxjs';
import * as fromActionEvents from './action-events.actions';
import {
  selectActionEvents,
  selectActionEventsIsLoaded, selectActionEventsIsLoading,
  selectLastActionEvents,
  selectLastActionEventsIsLoaded,
  selectLastActionEventsIsLoading
} from './action-events.selectors';
import { ApiResponsePaginated } from '../store.model';
import {
  ActionEvent,
  ActionEventInsertDto,
  Request,
  Tables
} from '@exe/client/client-web/core/supabase';
import { Actions, ofType } from '@ngrx/effects';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class ActionEventsFacadeService {
  #store = inject(Store);
  #actions$ = inject(Actions);

  isActionEventsLoading = toSignal(this.#store.select(selectActionEventsIsLoading), { initialValue: false });
  isActionEventsLoaded = toSignal(this.#store.select(selectActionEventsIsLoaded), { initialValue: false });
  actionEvents = toSignal(this.#store.select(selectActionEvents));

  isLastActionEventsLoading = toSignal(this.#store.select(selectLastActionEventsIsLoading), { initialValue: false });
  isLastActionEventsLoaded = toSignal(this.#store.select(selectLastActionEventsIsLoaded), { initialValue: false });
  lastActionEvents = toSignal(this.#store.select(selectLastActionEvents), { initialValue: [] as ActionEvent[] });


  clearActionEvents(): void {
    return this.#store.dispatch(fromActionEvents.clearActionEvents());
  }

  fetchActionEvents(getActionEventsRequestDto: Request): void {
    getActionEventsRequestDto = {
      ...getActionEventsRequestDto,
      select: `*, action:${Tables.ACTIONS}(*), product:${Tables.PRODUCTS}!products_lastActionEventId_fkey(*), user:${Tables.USERS}(*)`
    }
    return this.#store.dispatch(fromActionEvents.fetchActionEvents({ payload: getActionEventsRequestDto }));
  }

  isActionEventsLoaded$(): Observable<boolean> {
    return this.#store.select(selectActionEventsIsLoaded).pipe(
      filter(actionEventsIsLoaded => !!actionEventsIsLoaded)
    );
  }

  getActionEvents$(): Observable<ApiResponsePaginated<ActionEvent>> {
    return this.#store.select(selectActionEvents).pipe(
      filter(actionEvents => !!actionEvents)
    );
  }

  addActionEvent(actionEventInsertDto: ActionEventInsertDto): Observable<void> {
    this.#store.dispatch(fromActionEvents.addActionEvent({ payload: actionEventInsertDto }));

    return this.#actions$.pipe(
      ofType(fromActionEvents.addActionEventSuccess),
      first()
    ) as Observable<void>;
  }

  addActionEventBatch(actionEventInsertBatchDto: ActionEventInsertDto[]): Observable<void> {
    this.#store.dispatch(fromActionEvents.addActionEventBatch({ payload: actionEventInsertBatchDto}));

    return this.#actions$.pipe(
      ofType(fromActionEvents.addActionEventBatchSuccess),
      first()
    ) as Observable<void>;
  }

  fetchLastActionEvents(): void {
    this.#store.dispatch(fromActionEvents.fetchLastActionEvents());
  }

}
