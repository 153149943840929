import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { TRANSLATIONS, URL_TREE } from './navigation.model'
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private readonly DEFAULT_LANGUAGE = 'pl';

  constructor(
    private router: Router,
    private location: Location
  ) {}

  // Gets current url as string
  getCurrentUrl(): string {
    return this.router.url;
  }

  getCurrentUrlWithoutQueryParams(): string {
    const url = this.getCurrentUrl();
    const queryStringIndex = url.indexOf('?');
    if (queryStringIndex !== -1) {
      return url.slice(0, queryStringIndex);
    }
    return url
  }

  // Gets current url as observable string
  getCurrentUrl$(): Observable<string> {
    return this.router.events.pipe(
      map(() => this.router.url)
    );
  }

  getCurrentUrlWithoutQueryParams$(): Observable<string> {
    return this.getCurrentUrl$().pipe(
      map(currentUrl => {
        const queryStringIndex = currentUrl.indexOf('?');
        if (queryStringIndex !== -1) {
          return currentUrl.slice(0, queryStringIndex);
        }
        return currentUrl
      })
    )
  }

  private getTranslationForSegment(segment: string): string | null {
    const key = segment.toLowerCase();
    return TRANSLATIONS[key]?.[this.DEFAULT_LANGUAGE] || null;
  }

  private getTranslationForNumericId(id: string, urlPath: string[]): string | null {
    const index = urlPath.indexOf(id);
    if (index <= 0) return null;

    const previousSegment = urlPath[index - 1].toLowerCase();
    if (previousSegment === 'orders') {
      return TRANSLATIONS['order-details'][this.DEFAULT_LANGUAGE];
    }

    if (index === urlPath.length - 1) {
      return TRANSLATIONS['product'][this.DEFAULT_LANGUAGE];
    }

    return null;
  }

  private translateSegment(segment: string, urlPath: string[]): string {
    // Try to get direct translation first
    const directTranslation = this.getTranslationForSegment(segment);
    if (directTranslation) return directTranslation;

    // If numeric and we have URL context, try to determine the type
    if (!isNaN(Number(segment))) {
      const idTranslation = this.getTranslationForNumericId(segment, urlPath);
      if (idTranslation) return idTranslation;
    }

    // Return original if no translation found
    return segment;
  }

  // Gets current breadcrumbs as string array
  getBreadcrumbs(): string[] {
    const segments = this.getCurrentUrlWithoutQueryParams().split('/').filter(segment => segment.length);
    return segments.map(segment => this.translateSegment(segment, segments));
  }

  // Gets current breadcrumbs as observable string array
  getBreadcrumbs$(): Observable<string[]> {
    return this.getCurrentUrlWithoutQueryParams$().pipe(
      map(currentUrl => {
        const segments = currentUrl.split('/').filter(segment => segment.length);
        return segments.map(segment => this.translateSegment(segment, segments));
      }),
    );
  }

  // Navigates to specified path
  navigateTo(path: (string | number)[], navigationExtras?: NavigationExtras): void {
    this.router.navigate(path, navigationExtras);
  }

  navigateBack(): void {
    this.location.back();
  }

  getParams(): any {
    const getParameters : any = (route: ActivatedRouteSnapshot) => ({
      ...route.params,
      ...route.children.reduce((acc: any, child: any) =>
        ({ ...getParameters(child), ...acc }), {})
    });
    return getParameters(this.router.routerState.snapshot.root);
  }

  ///////////////////////////////////
  // APPLICATION SPECIFIC NAVIGATION
  ///////////////////////////////////

  navigateToDashboard(): void {
    this.navigateTo([
        URL_TREE.DASHBOARD.URL
    ]);
  }

  navigateToLogin(): void {
    this.navigateTo([
      URL_TREE.AUTH.URL,
      URL_TREE.AUTH.LOGIN.URL
    ]);
  }

  navigateToProduction(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.PRODUCTION.URL
    ]);
  }

  navigateToValuation(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.VALUATION.URL
    ])
  }

  navigateToTrace(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.TRACE.URL
    ]);
  }

  navigateToResultDetails(id: number): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.ACTIONS.URL,
      URL_TREE.DASHBOARD.ACTIONS.TABLE.URL
    ],{
      queryParams: {
        id
      }
    });
  }

  navigateToStatistics(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.STATISTICS.URL
    ]);
  }

  navigateToUsersStatistics(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.STATISTICS.URL,
      URL_TREE.DASHBOARD.STATISTICS.USERS.URL
    ]);
  }

  navigateToOrdersStatistics(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.STATISTICS.URL,
      URL_TREE.DASHBOARD.STATISTICS.ORDERS.URL
    ]);
  }

  navigateToConfiguration(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL
    ]);
  }

  navigateToConfigurationUsersTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.USERS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.USERS.TABLE.URL
    ]);
  }

  navigateToConfigurationActionsTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.ACTIONS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.ACTIONS.TABLE.URL
    ]);
  }

  navigateToConfigurationCustomersTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.CUSTOMERS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.CUSTOMERS.TABLE.URL
    ]);
  }

  navigateToConfigurationProjectsTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.PROJECTS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.PROJECTS.TABLE.URL
    ]);
  }

  navigateToOrder(id: number): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.TRACE.URL,
      URL_TREE.DASHBOARD.TRACE.ORDERS.URL,
      id
    ])
  }

  navigateToProduct(orderId: number, productId: number): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.TRACE.URL,
      URL_TREE.DASHBOARD.TRACE.ORDERS.URL,
      orderId,
      productId
    ])
  }
}
