import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import * as fromOrganisations from './organisations.actions';
import { selectOrganisation, selectOrganisationIsLoaded, selectOrganisationIsLoading } from './organisations.selectors';
import { Organisation, OrganisationInsertDto } from '@exe/client/client-web/core/supabase';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsFacadeService {
  constructor(private readonly store: Store) {}

  clearOrganisation(): void {
    return this.store.dispatch(fromOrganisations.clearOrganisation());
  }

  fetchOrganisation(organisationId: number): void {
    return this.store.dispatch(fromOrganisations.fetchOrganisation({ payload: organisationId }));
  }

  isOrganisationLoading$(): Observable<boolean> {
    return this.store.select(selectOrganisationIsLoading).pipe(
      filter(actionsIsLoading => !!actionsIsLoading)
    );
  }

  isOrganisationLoaded$(): Observable<boolean> {
    return this.store.select(selectOrganisationIsLoaded).pipe(
      filter(organisationIsLoaded => !!organisationIsLoaded)
    );
  }

  getOrganisation$(): Observable<Organisation> {
    return this.store.select(selectOrganisation)
  }

  createOrganisation(organisationsCreateDto: OrganisationInsertDto): void {
    return this.store.dispatch(fromOrganisations.createOrganisation({ payload: organisationsCreateDto }));
  }

  isSubscriptionActive$(): Observable<boolean> {
    return this.store.select(selectOrganisation).pipe(
      map(organisation => organisation.subscriptionExpirationDate! > new Date().toISOString())
    );
  }

  getDaysTillSubscriptionExpiration$(): Observable<number> {
    return this.store.select(selectOrganisation).pipe(
      map(organisation => {
        const subscriptionExpirationDate = new Date(organisation.subscriptionExpirationDate!);
        return Math.ceil((subscriptionExpirationDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
      })
    );
  }
}
