import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ProductsPerUserStatistics,
  ActionsPerUserStatistics,
  OrdersPerCustomerStatistics,
  OrdersPerMonthStatistics, OrderSummaryStatistics
} from './statistics.model';

const prefix = '[Statistics]';

export const clearActionsPerUser = createAction(`${prefix} Clear actions per user`);
export const fetchActionsPerUser = createAction(`${prefix} Fetch actions per user`);
export const fetchActionsPerUserSuccess = createAction(`${prefix} Fetch actions per user success`, props<{ payload: ActionsPerUserStatistics }>());
export const fetchActionsPerUserFailure = createAction(`${prefix} Fetch actions per user failure`, props<{ payload: HttpErrorResponse }>());

export const clearProductsPerUser = createAction(`${prefix} Clear products per user`);
export const fetchProductsPerUser = createAction(`${prefix} Fetch products per user`);
export const fetchProductsPerUserSuccess = createAction(`${prefix} Fetch products per user success`, props<{ payload: ProductsPerUserStatistics }>());
export const fetchProductsPerUserFailure = createAction(`${prefix} Fetch products per user failure`, props<{ payload: HttpErrorResponse }>());

export const clearOrdersPerMonth = createAction(`${prefix} Clear orders per month`);
export const fetchOrdersPerMonth = createAction(`${prefix} Fetch orders per month`);
export const fetchOrdersPerMonthSuccess = createAction(`${prefix} Fetch orders per month success`, props<{ payload: OrdersPerMonthStatistics }>());
export const fetchOrdersPerMonthFailure = createAction(`${prefix} Fetch orders per month failure`, props<{ payload: HttpErrorResponse }>());

export const clearOrdersPerCustomer = createAction(`${prefix} Clear orders per customer`);
export const fetchOrdersPerCustomer = createAction(`${prefix} Fetch orders per customer`);
export const fetchOrdersPerCustomerSuccess = createAction(`${prefix} Fetch orders per customer success`, props<{ payload: OrdersPerCustomerStatistics }>());
export const fetchOrdersPerCustomerFailure = createAction(`${prefix} Fetch orders per customer failure`, props<{ payload: HttpErrorResponse }>());

export const clearOrderSummary = createAction(`${prefix} Clear order summary`);
export const fetchOrderSummary = createAction(`${prefix} Fetch order summary`, props<{ orderId: number }>());
export const fetchOrderSummarySuccess = createAction(`${prefix} Fetch order summary success`, props<{ payload: OrderSummaryStatistics }>());
export const fetchOrderSummaryFailure = createAction(`${prefix} Fetch order summary failure`, props<{ payload: HttpErrorResponse }>());
