import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatisticsState } from './statistics.reducers';

export const selectStatisticsState = createFeatureSelector<StatisticsState>('statistics');

export const selectActionsPerUser = createSelector(selectStatisticsState, (state) => state.users.actionsPerUser.data);
export const selectActionsPerUserIsLoading = createSelector(selectStatisticsState, (state) => state.users.actionsPerUser.isLoading);
export const selectActionsPerUserIsLoaded = createSelector(selectStatisticsState, (state) => state.users.actionsPerUser.isLoaded);
export const selectActionsPerUserErrors = createSelector(selectStatisticsState, (state) => state.users.actionsPerUser.errors);

export const selectProductsPerUser = createSelector(selectStatisticsState, (state) => state.users.productsPerUser.data);
export const selectProductsPerUserIsLoading = createSelector(selectStatisticsState, (state) => state.users.productsPerUser.isLoading);
export const selectProductsPerUserIsLoaded = createSelector(selectStatisticsState, (state) => state.users.productsPerUser.isLoaded);
export const selectProductsPerUserErrors = createSelector(selectStatisticsState, (state) => state.users.productsPerUser.errors);

export const selectOrdersPerMonth = createSelector(selectStatisticsState, (state) => state.orders.ordersPerMonth.data);
export const selectOrdersPerMonthIsLoading = createSelector(selectStatisticsState, (state) => state.orders.ordersPerMonth.isLoading);
export const selectOrdersPerMonthIsLoaded = createSelector(selectStatisticsState, (state) => state.orders.ordersPerMonth.isLoaded);
export const selectOrdersPerMonthErrors = createSelector(selectStatisticsState, (state) => state.orders.ordersPerMonth.errors);

export const selectOrdersPerCustomer = createSelector(selectStatisticsState, (state) => state.orders.ordersPerCustomer.data);
export const selectOrdersPerCustomerIsLoading = createSelector(selectStatisticsState, (state) => state.orders.ordersPerCustomer.isLoading);
export const selectOrdersPerCustomerIsLoaded = createSelector(selectStatisticsState, (state) => state.orders.ordersPerCustomer.isLoaded);
export const selectOrdersPerCustomerErrors = createSelector(selectStatisticsState, (state) => state.orders.ordersPerCustomer.errors);

export const selectOrderSummary = createSelector(selectStatisticsState, (state) => state.orders.orderSummary.data);
export const selectOrderSummaryIsLoading = createSelector(selectStatisticsState, (state) => state.orders.orderSummary.isLoading);
export const selectOrderSummaryIsLoaded = createSelector(selectStatisticsState, (state) => state.orders.orderSummary.isLoaded);
export const selectOrderSummaryErrors = createSelector(selectStatisticsState, (state) => state.orders.orderSummary.errors);

