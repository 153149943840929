import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponseState, initialApiResponseState } from '../store.model';
import { Organisation } from '@exe/client/client-web/core/supabase';
import * as fromOrganisations from './index';

export interface OrganisationsState {
  organisation: ApiResponseState<Organisation>;
}

export const initialOrganisationsState: OrganisationsState = {
  organisation: initialApiResponseState
};

const reducer = createReducer(
  initialOrganisationsState,
  on(fromOrganisations.clearOrganisation, (state) => {
    return {
      ...state,
      organisation: initialOrganisationsState.organisation
    };
  }),
  on(fromOrganisations.fetchOrganisation, (state) => {
    return {
      ...state,
      organisation: {
        ...state.organisation,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromOrganisations.fetchOrganisationSuccess, (state, { payload }) => {
    return {
      ...state,
      organisation: {
        ...state.organisation,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromOrganisations.fetchOrganisationFailure, (state, { payload }) => {
    return {
      ...state,
      organisation: {
        ...state.organisation,
        isLoading: false,
        isLoaded: false,
        errors: [...state.organisation.errors, payload]
      }
    };
  })
);

export function organisationsReducer(state = initialOrganisationsState, actions: Action) {
  return reducer(state, actions);
}
