import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { URL_TREE } from './navigation.model'
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private location: Location
  ) {}

  // Gets current url as string
  getCurrentUrl(): string {
    return this.router.url;
  }

  getCurrentUrlWithoutQueryParams(): string {
    const url = this.getCurrentUrl();
    const queryStringIndex = url.indexOf('?');
    if (queryStringIndex !== -1) {
      return url.slice(0, queryStringIndex);
    }
    return url
  }

  // Gets current url as observable string
  getCurrentUrl$(): Observable<string> {
    return this.router.events.pipe(
      map(() => this.router.url)
    );
  }

  getCurrentUrlWithoutQueryParams$(): Observable<string> {
    return this.getCurrentUrl$().pipe(
      map(currentUrl => {
        const queryStringIndex = currentUrl.indexOf('?');
        if (queryStringIndex !== -1) {
          return currentUrl.slice(0, queryStringIndex);
        }
        return currentUrl
      })
    )
  }

  // Gets current breadcrumbs as string array
  getBreadcrumbs(): string[] {
    return this.getCurrentUrlWithoutQueryParams().split('/').filter(segment => segment.length);
  }

  // Gets current breadcrumbs as observable string array
  getBreadcrumbs$(): Observable<string[]> {
    return this.getCurrentUrlWithoutQueryParams$().pipe(
      map(currentUrl => currentUrl.split('/').filter(segment => segment.length)),
    );
  }

  // Navigates to specified path
  navigateTo(path: (string | number)[], navigationExtras?: NavigationExtras): void {
    this.router.navigate(path, navigationExtras);
  }

  navigateBack(): void {
    this.location.back();
  }

  getParams(): any {
    const getParameters : any = (route: ActivatedRouteSnapshot) => ({
      ...route.params,
      ...route.children.reduce((acc: any, child: any) =>
        ({ ...getParameters(child), ...acc }), {})
    });
    return getParameters(this.router.routerState.snapshot.root);
  }


  ///////////////////////////////////
  // APPLICATION SPECIFIC NAVIGATION
  ///////////////////////////////////

  navigateToDashboard(): void {
    this.navigateTo([
        URL_TREE.DASHBOARD.URL
    ]);
  }

  navigateToLogin(): void {
    this.navigateTo([
      URL_TREE.AUTH.URL,
      URL_TREE.AUTH.LOGIN.URL
    ]);
  }

  navigateToProduction(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.PRODUCTION.URL
    ]);
  }

  navigateToTrace(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.TRACE.URL
    ]);
  }

  navigateToResultDetails(id: number): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.ACTIONS.URL,
      URL_TREE.DASHBOARD.ACTIONS.TABLE.URL
    ],{
      queryParams: {
        id
      }
    });
  }

  navigateToStatistics(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.STATISTICS.URL
    ]);
  }

  navigateToConfiguration(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL
    ]);
  }

  navigateToConfigurationUsersTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.USERS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.USERS.TABLE.URL
    ]);
  }

  navigateToConfigurationActionsTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.ACTIONS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.ACTIONS.TABLE.URL
    ]);
  }

  navigateToConfigurationCustomersTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.CUSTOMERS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.CUSTOMERS.TABLE.URL
    ]);
  }

  navigateToConfigurationProjectsTable(): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.PROJECTS.URL,
      URL_TREE.DASHBOARD.CONFIGURATION.PROJECTS.TABLE.URL
    ]);
  }

  navigateToOrder(id: number): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.TRACE.URL,
      URL_TREE.DASHBOARD.TRACE.ORDERS.URL,
      id
    ])
  }

  navigateToProduct(orderId: number, productId: number): void {
    this.navigateTo([
      URL_TREE.DASHBOARD.URL,
      URL_TREE.DASHBOARD.TRACE.URL,
      URL_TREE.DASHBOARD.TRACE.ORDERS.URL,
      orderId,
      productId
    ])
  }
}
