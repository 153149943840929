export const URL_TREE = {
  AUTH: {
    URL: 'auth',
    LOGIN: {
      URL: 'login'
    }
  },
  DASHBOARD: {
    URL: 'dashboard',
    ACTIONS: {
      URL: 'actions',
      TABLE: {
        URL: 'table'
      },
    },
    PRODUCTION: {
      URL: 'production'
    },
    STATISTICS: {
      URL: 'statistics'
    },
    TRACE: {
      URL: 'trace',
      ORDERS: {
        URL: 'orders'
      }
    },
    CONFIGURATION: {
      URL: 'configuration',
      USERS: {
        URL: 'users',
        TABLE: {
          URL: 'table'
        }
      },
      ACTIONS: {
        URL: 'actions',
        TABLE: {
          URL: 'table'
        }
      },
      CUSTOMERS: {
        URL: 'customers',
        TABLE: {
          URL: 'table'
        }
      },
      PROJECTS: {
        URL: 'projects',
        TABLE: {
          URL: 'table'
        }
      }
    }
  },
  USER_CHECK: {
    URL: 'user-check',
  },
  NOT_FOUND: {
    URL: 'not-found'
  },
  NOT_AUTHORIZED: {
    URL: 'not-authorized'
  }
}
