import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService, Tables } from '@exe/client/client-web/core/supabase';
import * as fromActionEvents from './index';

@Injectable()
export class ActionEventsEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getActionEvents = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionEvents.fetchActionEvents),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery(payload));
      }),
      map(({data , count}) => fromActionEvents.fetchActionEventsSuccess({
        payload: {
          array: data!,
          totalCount: count!
        }
      })),
      catchError((error) => of(fromActionEvents.fetchActionEventsFailure({ payload: error })))
    )
  );

  addActionEvent = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionEvents.addActionEvent),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('actionEvents').insert(payload));
      }),
      map(() => fromActionEvents.addActionEventSuccess()),
      catchError((error) => of(fromActionEvents.addActionEventFailure({ payload: error })))
    )
  );

  addActionEventBatch = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionEvents.addActionEventBatch),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('actionEvents').insert(payload));
      }),
      map(() => fromActionEvents.addActionEventBatchSuccess()),
      catchError((error) => of(fromActionEvents.addActionEventBatchFailure({ payload: error })))
    )
  );

  getLastActionEvents = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActionEvents.fetchLastActionEvents),
      switchMap(() => {
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0); // Ustaw początek dnia
        return from(
          this.supabaseService.client.from(Tables.ACTION_EVENTS)
            .select(`
              *,
              action:${Tables.ACTIONS}(*),
              product:${Tables.PRODUCTS}!actionEvents_productId_fkey(
                *,
                order:${Tables.ORDERS}(
                  *,
                  customer:${Tables.CUSTOMERS}(*)
                )
              ),
              user:${Tables.USERS}(*)
            `)
            .gt('creationDate', startOfDay.toISOString())
            .order('id', { ascending: false }))
      }),
      map(({data }) => fromActionEvents.fetchLastActionEventsSuccess({
        payload: data!
      })),
      catchError((error) => of(fromActionEvents.fetchLastActionEventsFailure({ payload: error })))
    )
  );
}
