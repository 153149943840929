import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SupabaseService, Tables } from '@exe/client/client-web/core/supabase';
import * as fromUsers from './index';

@Injectable()
export class UsersEffects {
  constructor(
    private readonly actions$: Actions,
    private supabaseService: SupabaseService
  ) {}

  getUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.fetchUsers),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery(payload));
      }),
      map(({data , count}) => fromUsers.fetchUsersSuccess({
        payload: {
          array: data!,
          totalCount: count!
        }
      })),
      catchError((error) => of(fromUsers.fetchUsersFailure({ payload: error })))
    )
  );

  getLoggedInUser = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.fetchLoggedInUser),
      switchMap(({ payload }) => {
        return from(this.supabaseService.buildSelectQuery({
          skip: 0,
          take: 1,
          select: `*, organisation:${Tables.ORGANISATIONS}(*)'`,
          filters: [
            {
              column: 'id',
              operator: 'eq',
              value: payload
            }
          ],
          table: 'users',
          order: {
            column: 'id',
            options: {
              ascending: true
            },
          }
        }))
      }),
      map(({data }) => fromUsers.fetchLoggedInUserSuccess({
        payload: data![0]
      })),
      catchError((error) => of(fromUsers.fetchLoggedInUserFailure({ payload: error })))
    )
  )

  addUser = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.addUser),
      switchMap(({ payload }) => {
        return from(this.supabaseService.client.from('users').insert(payload));
      }),
      map(() => fromUsers.addUserSuccess()),
      // catchError((error) => of(fromActions.addActionFailure({ payload: error })))
    )
  );
}
