import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStatistics from './statistics.actions';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  selectProductsPerUser,
  selectActionsPerUser,
  selectActionsPerUserIsLoaded,
  selectProductsPerUserIsLoaded,
  selectOrdersPerMonthIsLoaded,
  selectOrdersPerCustomerIsLoaded,
  selectOrdersPerCustomer,
  selectOrdersPerMonth, selectOrderSummaryIsLoaded, selectOrderSummary
} from './statistics.selectors';

@Injectable({
  providedIn: 'root'
})
export class StatisticsFacadeService {
  #store = inject(Store);

  actionsPerUserLoaded = toSignal(this.#store.select(selectActionsPerUserIsLoaded));
  actionsPerUser = toSignal(this.#store.select(selectActionsPerUser));
  productsPerUserLoaded = toSignal(this.#store.select(selectProductsPerUserIsLoaded));
  productsPerUser = toSignal(this.#store.select(selectProductsPerUser));
  ordersPerMonthLoaded = toSignal(this.#store.select(selectOrdersPerMonthIsLoaded))
  ordersPerMonth = toSignal(this.#store.select(selectOrdersPerMonth));
  ordersPerCustomerLoaded = toSignal(this.#store.select(selectOrdersPerCustomerIsLoaded));
  ordersPerCustomer = toSignal(this.#store.select(selectOrdersPerCustomer));
  orderSummaryLoaded = toSignal(this.#store.select(selectOrderSummaryIsLoaded));
  orderSummary = toSignal(this.#store.select(selectOrderSummary));

  clearStatistics(): void {
    return this.#store.dispatch(fromStatistics.clearActionsPerUser());
  }

  fetchActionsPerUser(): void {
    return this.#store.dispatch(fromStatistics.fetchActionsPerUser());
  }

  fetchProductsPerUser(): void {
    return this.#store.dispatch(fromStatistics.fetchProductsPerUser());
  }

  fetchOrdersPerMonth(): void {
    return this.#store.dispatch(fromStatistics.fetchOrdersPerMonth());
  }

  fetchOrdersPerCustomer(): void {
    return this.#store.dispatch(fromStatistics.fetchOrdersPerCustomer());
  }

  fetchOrderSummary(orderId: number): void {
    return this.#store.dispatch(fromStatistics.fetchOrderSummary({
      orderId: orderId
    }));
  }
}
