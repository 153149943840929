import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { SupabaseService, User, Organisation } from '@exe/client/client-web/core/supabase';
import { NavigationService } from '@exe/client/client-web/shared/navigation';
import { OrganisationsFacadeService, UsersFacadeService } from '@exe/client/client-web/core/store';
import { Observable, of, from } from 'rxjs';
import { catchError, switchMap, filter, first, timeout, map } from 'rxjs/operators';

export const LoginGuard: CanMatchFn = () => {
  const supabaseService = inject(SupabaseService);
  const navigationService = inject(NavigationService);
  const usersFacadeService = inject(UsersFacadeService);
  const organisationFacadeService = inject(OrganisationsFacadeService);

  const handleLoggedInUser = (userId: string): Observable<boolean> => {
    return usersFacadeService.getLoggedInUser$().pipe(
      first(),
      switchMap(user => fetchUserIfNeeded(user, userId)),
      switchMap(user => handleOrganisation(user)),
      timeout(10000),
      catchError(() => navigateToLogin())
    );
  };

  const fetchUserIfNeeded = (user: User, userId: string): Observable<User> => {
    if (!user || user.id !== userId) {
      usersFacadeService.fetchLoggedInUser(userId);
      return usersFacadeService.getLoggedInUser$().pipe(
        filter(Boolean),
        first()
      );
    }
    return of(user);
  };

  const handleOrganisation = (user: User): Observable<boolean> => {
    if (!user?.organisationId) {
      return navigateToLogin();
    }

    return organisationFacadeService.getOrganisation$().pipe(
      first(),
      switchMap(organisation => fetchOrganisationIfNeeded(organisation, user.organisationId as number)),
      map(() => true),
      timeout(10000),
      catchError(() => navigateToLogin())
    );
  };

  const fetchOrganisationIfNeeded = (organisation: Organisation, organisationId: number): Observable<Organisation> => {
    if (!organisation || organisation.id !== organisationId) {
      organisationFacadeService.fetchOrganisation(organisationId);
      return organisationFacadeService.getOrganisation$().pipe(
        filter(Boolean),
        first()
      );
    }
    return of(organisation);
  };

  const navigateToLogin = (): Observable<boolean> => {
    navigationService.navigateToLogin();
    return of(false);
  };

  return from(supabaseService.client.auth.getSession()).pipe(
    switchMap(({ data: { session } }) =>
      session ? handleLoggedInUser(session.user.id) : navigateToLogin()
    ),
    catchError(() => navigateToLogin())
  );
};
