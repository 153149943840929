export interface ActionCounts {
  [actionName: string]: number;
}

export interface ActionsPerUserStatistics {
  [userName: string]: ActionCounts;
}

export interface ProductsPerUserStatistics {
  [userName: string]: number;
}

export interface OrdersPerMonthStatistics {
  [month: string]: number;
}

export interface OrdersPerCustomerStatistics {
  [customerName: string]: number;
}

export interface OrderSummaryStatistics {
  [actionName: string]: number;
}
