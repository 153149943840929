import { createReducer, on, Action } from '@ngrx/store';
import { ApiResponsePaginated, ApiResponseState, initialApiResponseState } from '../store.model';
import { User } from '@exe/client/client-web/core/supabase';
import * as fromUsers from './index';


export interface UsersState {
  users: ApiResponseState<ApiResponsePaginated<User>>;
  loggedInUser: ApiResponseState<User>
}

export const initialUsersState: UsersState = {
  users: initialApiResponseState,
  loggedInUser: initialApiResponseState
};

const reducer = createReducer(
  initialUsersState,
  on(fromUsers.clearUsers, (state) => {
    return {
      ...state,
      users: initialUsersState.users
    };
  }),
  on(fromUsers.fetchUsers, (state) => {
    return {
      ...state,
      users: {
        ...state.users,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromUsers.fetchUsersSuccess, (state, { payload }) => {
    return {
      ...state,
      users: {
        ...state.users,
        isLoading: false,
        isLoaded: true,
        data: payload
      }
    };
  }),
  on(fromUsers.fetchUsersFailure, (state, { payload }) => {
    return {
      ...state,
      users: {
        ...state.users,
        isLoading: false,
        isLoaded: false,
        errors: [...state.users.errors, payload]
      }
    };
  }),
  on(fromUsers.clearLoggedInUser, (state) => {
    return {
      ...state,
      loggedInUser: initialUsersState.loggedInUser
    };
  }),
  on(fromUsers.fetchLoggedInUser, (state) => {
    return {
      ...state,
      loggedInUser: {
        ...state.loggedInUser,
        isLoading: true,
        isLoaded: false
      }
    };
  }),
  on(fromUsers.fetchLoggedInUserSuccess, (state, { payload }) => {
    return {
      ...state,
      loggedInUser: {
        ...state.loggedInUser,
        isLoading: false,
        isLoaded: true,
        data: {
          ...payload,
          organisation: payload.organisation
        }
      }
    };
  })
);

export function usersReducer(state = initialUsersState, actions: Action) {
  return reducer(state, actions);
}
