import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgrxStoreModule } from '@exe/client/client-web/core/store';
import { HttpErrorsHandlerModule } from '@exe/client/client-web/core/http-errors-handler';
import { TranslocoRootModule } from '@exe/client/client-web/core/transloco';
import { SupabaseModule } from '../../../supabase/src/lib/supabase.module';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';

@NgModule({
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgrxStoreModule,
    HttpErrorsHandlerModule,
    TranslocoRootModule,
    SupabaseModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgrxStoreModule,
    HttpErrorsHandlerModule,
    TranslocoRootModule,
    SupabaseModule
  ],
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useFactory: () => ({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {}
