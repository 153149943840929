import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrdersState } from './orders.reducers';

export const selectOrdersState = createFeatureSelector<OrdersState>('orders');

export const selectOrders = createSelector(selectOrdersState, (state) => state.orders.data);
export const selectOrdersIsLoading = createSelector(selectOrdersState, (state) => state.orders.isLoading);
export const selectOrdersIsLoaded = createSelector(selectOrdersState, (state) => state.orders.isLoaded);
export const selectOrdersErrors = createSelector(selectOrdersState, (state) => state.orders.errors);
export const selectOrdersTotalCount = createSelector(selectOrdersState, (state) => state.orders.data?.totalCount);

export const selectActiveOrders = createSelector(selectOrdersState, (state) => state.activeOrders.data);
export const selectActiveOrdersIsLoading = createSelector(selectOrdersState, (state) => state.activeOrders.isLoading);
export const selectActiveOrdersIsLoaded = createSelector(selectOrdersState, (state) => state.activeOrders.isLoaded);
